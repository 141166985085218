.logo{
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 9999 !important;
    width: 11%;
}
.logo img{
    max-width: 100%;
}
.slider{
    position: relative;
}
.footer{
    padding: 120px 0;
    background-color:#48505E;
    margin-top: 35px;
}

.svgset b:hover {
    font-size: 17px;
    transition: 0.2s;
    cursor: pointer;
}
.svgset b:hover>svg{
    font-size: 17px;
    transition: 0.2s;
    cursor: pointer;
    margin-left: -5px;
    
}
button.btnn {
    height: 36px;
    padding: 2px 30px;
    border: 2px solid;
    border-radius: 34px;
    color: #fff;
    background: none;
    margin-top: 20px;
    font-weight: 600;
}
button.btnn:hover{
    color:#48505E;
    background-color: #fff;
    transition: 0.5s;
    
}
@media (min-width:0px) and (max-width:680px) {
    .noneedMb{
        display: none;
    }
}
.aboutText{
    text-align: center;
    font-size: 16px;
    color: #333;
}
.forCls{
    background: #f0f4fb;
}
.aboutBanner img {
    width: 100%;
}

.meetTeam {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.teamSection {
    padding: 20px 0;
}

.our_team {
    padding-bottom: 20px;
    text-align: center;
}

.our_team .pic {
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #ff595e;
    padding: 70px 20px 30px;
    margin-bottom: 20px;
    position: relative;
}

.our_team .description {
    font-size: 14px;
    color: #fff;
}

.our_team .pic img {
    width: 101%;
    height: 101%;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    left: -1px;
    transition: all 0.6s ease 0s;
}
/*
.our_team:hover .pic img {
    transform: rotate(90deg);
    transform-origin: 95% 40% 0;
}
*/
.our_team .title {
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: #7740ff;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.our_team .post {
    display: block;
    font-size: 15px;
    color: #333;
    text-transform: capitalize;
    margin-bottom: 10px;
    position: relative;
}

.our_team .post:after {
    content: "";
    width: 30px;
    height: 3px;
    background: #eee;
    margin: 0 auto;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
}

.our_team .social {
    padding: 0;
    margin: 25px 0 0 0;
    list-style: none;
}

.our_team .social li {
    display: inline-block;
    margin-right: 5px;
}

.our_team .social li a {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    background: #7740ff;
    font-size: 15px;
    color: #fff;
}

.our_team .social li a:hover {
    background: #ff595e;
}
.runText{
    text-align: center;
}
.forStyles{
    text-align: center;
}
.styles{
    color: #ff595e;
}
.aboutText{
    text-align: center;
    font-size: 16px;
    color: #333;
}
.forCls{
    background: #f0f4fb;
}
.headinggs h1 {
    font-size: 2rem;
    text-align: center;
    margin: 26px;
    color: #4d4d4d;
  }
.liner {
  padding: 15px;
  border: 2px solid #f9f9f9;
  background-image: linear-gradient(#fff, #d5d5d5);
}

.liner label {
  color: #4d4d4d;
}

input.formmm {
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  background: #fff;
  padding: 0 15px;
  margin: 0 0 10px;
  height: 42px;
  outline: none;
}

.liner textarea {
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  background: #fff;
  padding: 0 15px;
  margin: 0 0 10px;
  outline: none;
}

.rotatedivbb {
  padding: 77px 0px;
  text-align: center;
}

.rotatediv {
  height: 83px;
  width: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #40b7ff;
  border-radius: 0 30px 30px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.rotatediv svg {
  height: 45px;
  width: 45px;
  fill: currentColor;
  color: #40b7ff;
}

.rotatedivbb:hover>.rotatediv {
  border-radius: 0 40px 40px 40px;
  background: #40b7ff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all .5s ease 0s;
}

.rotatedivbb:hover>.rotatediv svg {
  fill: currentColor;
  color: #fff;
  transform: rotate(316deg);
}
.rotatedivbb:hover>h3{
  color: #40b7ff;
}

.rotatedivbb h3 {
  font-size: 23px;
  font-weight: 500;
  color: #011627;
  margin-bottom: 10px;
  transition: all .5s ease 0s;
}
@media only screen and (max-width: 990px) {
    .our_team {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .our_team {
        overflow: hidden;
    }
}