
.logoDiv{
    max-width: 124px;
    margin: 5px;
}

.navBar{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.navBar a:hover{
    background: #133985;
    color: #fff;
    transition: 1s;
}
.navBar a{
    color: rgb(0, 0, 0);
    padding: 20px 15px;
    transition: 1s;
    font-size: 14px;
}
.navBar_top{
    padding: 0 15px;
    position: sticky;
    top: 0;
    z-index: 999999;
}
.selectBar{
    background: #026ea9;
    padding: 10px;
}
.sliderSimages{
    position: relative;
}
.sliderSimages img{
    width: 100%;
}
.topbar{
    padding: 5px 15px;
    background: #133985;
}
.colText{
    color: #fff;
    margin: 0 10px;
    font-size: 13px;
}
.rightColm{
    text-align: right;
}
.iconsBox{
    background: #fff;
    border-radius: 50%;
    color: #0d68ed;
    padding: 3px;
    font-size: 25px;
    margin: 0 5px;
    cursor: pointer;
}
.iconsBox:hover{
    background: #a19bec;
    color: #fff;
}
.findSchool{
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    margin-top: 7px;
}
.combobox{
    display: flex;
}
.orText{
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    margin: 7px 10px;
}
.searchBtn{
    margin-left: -10px;
    border-radius: 0;
}
.heading{
    font-weight: bold;
    color: #48505E;
    margin: 50px 0 0 0;
    font-size: 25px;
}
.runningText{
    color: #48505E;
    margin: 10px 0 0 0;
    font-size: 18px;
}
.map_box{
    background: #dddddd;
    padding: 15px;
    margin: 50px 0 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.map_box img{
    max-width: 80%;
}
.grayImgs{
    background: #F3F4F5;
    margin: 50px 0;
}
.imagesSections img{
    max-width: 100%;
}
.textSec{
    margin: 20px 10%;
}
.imagesBox img{
    width: 100%;
}
.imagesBox{
    position: relative;
    
}
.textOnImages{
    background: #ddddddcc;
    padding: 15px;
    color: #464646;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 20px;
    font-weight: bold;
}
.blueSections{
    margin: 50px 0;
    background: #133985;
}
.innerSection{
    background: url(../../images/school-pattern.png);
}
.innerTextbox{
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    text-align: center;
}
.headingText{
    font-size: 23px;
    font-weight: bold;
    color: #F3F4F5;
}
.runTextWhite{
    font-size: 18px;
    color: #fff;
}
.ourSchools{
    background: #F3F4F5;
    margin: 0px 0;
    padding: 30px 0;
}
.serviceBox{
    color: #696969;
    background: radial-gradient(transparent,#eee);
    font-family: 'Raleway', sans-serif;
    text-align: center;
    border-radius: 15px;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
    overflow: hidden;
    position: relative;
}
.serviceBox:before{
    content: "";
    background: var(--main-color);
    width: 150px;
    height: 150px;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.15);
    transform: rotate(45deg);
    position: absolute;
    top: -85px;
    left: -85px;
}
.serviceBox .title{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 15px 0;
}
.serviceBox .title:after{
    content: '';
    border-top: 5px dotted var(--main-color);
    height: 3px;
    width: 100px;
    margin: 11px auto 0;
    display: block;
    clear: both;
}
.serviceBox .description{
    color: #888;
    font-size: 15px;
    line-height: 25px;
    margin: 0 0 15px;
}
.serviceBox .service_icon{
    color: var(--main-color);
    font-size: 45px;
    line-height: 45px;
}
.serviceBox.red{  --main-color: #F12945; }
.serviceBox.blue{  --main-color: #2F5560; }
.serviceBox.yellow{  --main-color: #F3B202; }
@media only screen and (max-width: 990px){
    .serviceBox{ margin: 0 0 20px; }
}
.serviceBox img{
    width: 100%;
}

.navBar_top a{
    text-decoration: none;
}